import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/blog-avtar.svg'


const _hoisted_1 = { class: "space" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-8" }
const _hoisted_5 = { class: "single_blog" }
const _hoisted_6 = { class: "blog-title sairaM" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "blog_info" }
const _hoisted_9 = { class: "blog_date cmn_blog_info" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "blog_date cmn_blog_info"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "summary mt-5"
}
const _hoisted_14 = { class: "blog_cont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.blog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.blog.title), 1),
                _createElementVNode("img", {
                  class: "img-fluid",
                  src: _ctx.blog.featuredimage.url,
                  alt: ""
                }, null, 8, _hoisted_7),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: _ctx.accessTime,
                      alt: "",
                      class: "img-fluid"
                    }, null, 8, _hoisted_10),
                    _createElementVNode("p", null, _toDisplayString(_ctx.displayedTime), 1)
                  ]),
                  (_ctx.blog.author)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _cache[0] || (_cache[0] = _createElementVNode("img", {
                          src: _imports_0,
                          alt: "",
                          class: "img-fluid",
                          style: {"width":"40px"}
                        }, null, -1)),
                        _createElementVNode("p", null, _toDisplayString(_ctx.blog.author.name), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  innerHTML: _ctx.blog.content.html,
                  class: "blog_cont"
                }, null, 8, _hoisted_12),
                (_ctx.blog.summary)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "sairaM mb-3 cm-color" }, "Summary", -1)),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.blog.summary), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}