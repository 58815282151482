import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space cmn_hero_banner inner_cmn_hero text-center blog_sec" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "Breadcrums" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "d-inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          class: "cm-color",
          to: { name: 'Home' }
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        }),
        _createElementVNode("img", {
          src: _ctx.RightArrow,
          alt: "",
          class: "mx-2"
        }, null, 8, _hoisted_5),
        _createVNode(_component_router_link, {
          class: "cm-color",
          to: { name: 'Blog' }
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Blog")
          ])),
          _: 1
        }),
        _createElementVNode("img", {
          src: _ctx.RightArrow,
          alt: "",
          class: "mx-2"
        }, null, 8, _hoisted_6),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.title), 1)
      ])
    ])
  ]))
}